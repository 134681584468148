<template>
  <b-table
    striped
    hover
    small
    no-border-collapse
    :per-page="5000"
    :current-page="1"
    :items="items"
    :fields="fields"
  />
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable
  },
  model: {
    prop: 'items',
    event: 'update:item'
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    fields: [
      {
        key: 'option',
        label: 'Nome'
      },
      {
        key: 'total',
        label: 'Total Vendido'
      }
    ]
  })
}
</script>
