<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    variant="transparent"
    opacity="1"
    blur="5px"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="perfil.png"
          :statistic="items.all_users"
          statistic-title="Total de Usuários"
          :chart-data="[{ name: 'totalAccounts', data: [28, 40, 36, 52, 38, 60, 55] }]"
        />
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="money-bag.png"
          color="warning"
          :statistic="items.all_users_balance.toFixed(2)"
          statistic-title="Saldo total disponível"
          :chart-data="[{ name: 'totalEarnings', data: [28, 40, 36, 52, 38, 60, 55] } ]"
        />
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="perfil.png"
          :statistic="kFormatter(items.all_users_today)"
          statistic-title="Total de Usuários Hoje"
          :chart-data="[{ name: 'totalAccounts', data: [28, 40, 36, 52, 38, 60, 55] }]"
        />
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="money-bag.png"
          color="warning"
          :statistic="kFormatter(items.all_balance_added_today)"
          statistic-title="Saldo adicionado Hoje"
          :chart-data="[{ name: 'totalEarnings', data: [28, 40, 36, 52, 38, 60, 55] } ]"
        />
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center">
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="sms.png"
          :statistic="kFormatter(items.all_sms)"
          statistic-title="SMS"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="follower.png"
          :statistic="kFormatter(items.all_sn_actions)"
          statistic-title="Seguidores"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="instagram.png"
          :statistic="kFormatter(items.all_sn_accounts)"
          statistic-title="Seguidores"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="photos.png"
          :statistic="kFormatter(items.all_pack_of_photos)"
          statistic-title="Pack de Fotos"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="vps.png"
          :statistic="kFormatter(items.all_vps_vpn)"
          statistic-title="VPS / VPN"
        />
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center">
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="sms.png"
          :statistic="kFormatter(items.all_sms_option2)"
          statistic-title="SMS Opção 2 <br> Hoje"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="sms.png"
          :statistic="kFormatter(items.all_sms_option3)"
          statistic-title="SMS Opção 3 <br> Hoje"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="sms.png"
          :statistic="kFormatter(items.all_sms_option4)"
          statistic-title="SMS Opção 4 <br> Hoje"
        />
      </b-col>

      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="follower.png"
          :statistic="kFormatter(items.all_sn_actions_today)"
          statistic-title="Seguidores<br>(Hoje)"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <Analytic
          v-if="!loading"
          :data="items.all_balance_added_last_7_days"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <Accounts v-model="items.all_accounts" />
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from './StatisticCardWithAreaChart.vue'
import StatisticCardVertical from './StatisticCardVerticalMod.vue'
import Analytic from './Analytic.vue'
import Accounts from './Accounts.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    Analytic,
    Accounts
  },
  data: () => ({
    loading: true,
    items: []
  }),
  async mounted () {
    await this.getData()
  },
  methods: {
    kFormatter,
    async getData () {
      this.loading = true
      const url = '/admin/dashboard'

      await this.$http.get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
