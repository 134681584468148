<template>
  <b-card class="text-center">
    <img
      class="rounded-lg mb-1"
      :src="`${$api_host}/storage/assets/${icon}`"
      width="50px"
    >
    <div
      class="truncate"
    >
      <h2 class="mb-25 font-weight-bolder">
        {{ statistic }}
      </h2>
      <span
        class="text-nowrap"
        v-html="statisticTitle"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    statistic: {
      type: [Number, String],
      required: true
    },
    statisticTitle: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
